export const config = {
  // Your Github Converted Token To Know How To Get Your Token Look at Readme.md
  githubConvertedToken: "ZjFjZjQwMWI2MGJiNDgwYjgyNDY2OWFkY2RlZjYxNzRkMTM1MWVkYw==",

  // Your Github UserName
  githubUserName: "sjefvanleeuwen",

  // Your Github Repo Name Where You Have your issues as Blog
  githubRepo: "blog",

  // Set it to true if you have a Github app to add to this project
  // and fill the client ID & secret
  enableOAuth: true,
  OAuthClientID: 'Iv1.1240d206afb8e828',
  OAuthSecret: '45ba136d6463eb0282d1422cba552a2efdb528df',

  // Your Personal Blog Title
  title : "Sjef van Leeuwen" ,

  // Your Personal Blog Subtitle
  subtitle : "Software Engineer Personal Blog",

  // Header customization
  header: {
    backgroundColor: '#aaaaaa', // can be a CSS gradient
    backgroundColorDark: '#333333',
    titleColor: '#111111',
    titleColorDark: '#ffffff',
    subtitleColor: '#666666',
    subtitleColorDark: '#666666',
  },
};
